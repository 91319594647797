<template>
  <div>
    <b-card-normal ref="cardAction" title="Anasayfa ve Genel Görünüm Ayarları" icon="HomeIcon" :showLoading="show">
      <template v-slot:body>
        <b-row>
          <b-col cols="12" md="7">
            <b-card>
              <draggable v-model="form.moduller" handler=".draggable-task-handle" draggable=".item">
                <transition-group type="transition" name="flip-list">
                  <com-item
                    v-for="(item, i) in form.moduller"
                    :key="i + 0"
                    :modul="item"
                    @openModal="handlerOpenModal($event, i)"
                    :class="disabled(item.modul) ? 'item border-primary' : ''"
                  />
                </transition-group>
              </draggable>
            </b-card>
          </b-col>
          <b-col cols="12" md="5">
            <b-card class="rounded-0">
              <genel-gorunum :form="form.genel" />
            </b-card>
          </b-col>
        </b-row>
        <b-card-footer>
          <b-button
            variant="primary"
            size="lg"
            type="submit"
            class="rounded-0 float-right"
            :disabled="!firmaKNo"
            @click="handlerOnSubmit"
          >
            <feather-icon class="mr-25" icon="SaveIcon" size="24" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            <span class="align-middle">Kaydet</span>
          </b-button>
        </b-card-footer>
      </template>
    </b-card-normal>
    <ayar-top-bar-header
      :openModal="openTopBarHeaderModal"
      :form="form.moduller[selectModulIndex]"
      :colors="colors"
      @save="handlerOnSubmit"
      @closeModal="closeModal = $event"
    />
    <ayar-nav-bar-header
      :openModal="openNavBarHeaderModal"
      :form="form.moduller[selectModulIndex]"
      @save="handlerOnSubmit"
      @closeModal="closeModal = $event"
    />
    <ayar-slider
      :openModal="openSliderModal"
      :form="form.moduller[selectModulIndex]"
      @save="handlerOnSubmit"
      @closeModal="closeModal = $event"
    />
    <ayar-hakkimizda
      :openModal="openHakkimizdaModal"
      :form="form.moduller[selectModulIndex]"
      @save="handlerOnSubmit"
      @closeModal="closeModal = $event"
    />
    <ayar-tanitim-video
      :openModal="openTanitimVideoModal"
      :form="form.moduller[selectModulIndex]"
      @save="handlerOnSubmit"
      @closeModal="closeModal = $event"
    />
    <ayar-urun-tinitim
      :openModal="openUrunTanitimiModal"
      :form="form.moduller[selectModulIndex]"
      @save="handlerOnSubmit"
      @closeModal="closeModal = $event"
    />
    <ayar-blog
      :openModal="openBlogModal"
      :form="form.moduller[selectModulIndex]"
      @save="handlerOnSubmit"
      @closeModal="closeModal = $event"
    />
    <ayar-tavsiye-urunler
      :openModal="openTavsiyeUrunlerModal"
      :form="form.moduller[selectModulIndex]"
      @save="handlerOnSubmit"
      @closeModal="closeModal = $event"
    />
    <ayar-yorumlar
      :openModal="openMisafirYorumlariModal"
      :form="form.moduller[selectModulIndex]"
      @save="handlerOnSubmit"
      @closeModal="closeModal = $event"
    />
    <ayar-footer
      :openModal="openFooterModal"
      :form="form.moduller[selectModulIndex]"
      @save="handlerOnSubmit"
      @closeModal="closeModal = $event"
    />
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import BCardNormal from '@/@core/components/b-card-actions/BCardNormal.vue';
import draggable from 'vuedraggable';
import ComItem from './component/comItem.vue';
import GenelGorunum from './component/genelGorunum.vue';

import AyarTopBarHeader from './component/AyarTopBarHeader.vue';
import AyarNavBarHeader from './component/ayarNavBarHeader.vue';
import AyarSlider from './component/ayarSlider.vue';
import AyarHakkimizda from './component/ayarHakkimizda.vue';
import AyarTanitimVideo from './component/ayarTanitimVideo.vue';
import AyarUrunTinitim from './component/ayarUrunTanitim.vue';
import AyarBlog from './component/ayarBlog.vue';
import AyarTavsiyeUrunler from './component/ayarTavsiyeUrunler.vue';
import AyarYorumlar from './component/ayarYorumlar.vue';
import AyarFooter from './component/ayarFooter.vue';

import { computed, ref, watch } from '@vue/composition-api';
import store from '@/store';

import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
export default {
  components: {
    BCardNormal,
    ComItem,
    GenelGorunum,
    draggable,
    AyarTopBarHeader,
    AyarNavBarHeader,
    AyarSlider,
    AyarHakkimizda,
    AyarTanitimVideo,
    AyarUrunTinitim,
    AyarBlog,
    AyarTavsiyeUrunler,
    AyarYorumlar,
    AyarFooter,
  },
  setup() {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    expo.closeModal = ref(false);
    expo.openTopBarHeaderModal = ref(false);
    expo.openNavBarHeaderModal = ref(false);
    expo.openSliderModal = ref(false);
    expo.openHakkimizdaModal = ref(false);
    expo.openTanitimVideoModal = ref(false);
    expo.openUrunTanitimiModal = ref(false);
    expo.openBlogModal = ref(false);
    expo.openIstatiklerModal = ref(false);
    expo.openTavsiyeUrunlerModal = ref(false);
    expo.openMisafirYorumlariModal = ref(false);
    expo.openAboneOlModal = ref(false);
    expo.openFooterModal = ref(false);
    expo.selectModulIndex = ref(0);

    expo.form = ref({
      k_no: null,
      genel: [
        {
          color: 'blue',
          font_family: 'roboto',
          button_radius: 2,
          card_radius: 2,
        },
      ],
      moduller: [
        {
          modul: 'header_topbar',
          title: 'Header Top Bar ',
          statu: false,
          ayar: {
            telefon: true,
            sosyal: true,
            diller: true,
            kurlar: true,
            giris: true,
            backgroud: '#ffffff',
            color: '#717171',
          },
        },
        {
          modul: 'header_navbar',
          title: 'Header Nav Bar',
          statu: false,
          ayar: {
            logo: true,
            menu: true,
            giris: true,
            diller: true,
            kurlar: true,
            buton: {
              statu: false,
              baslik: '',
              url: '',
            },
          },
        },
        {
          modul: 'slider',
          title: 'Slideshow Alanı',
          statu: false,
          ayar: {
            mod: 'slide', //paralax, tek_resim
            arama: false,
          },
        },
        {
          modul: 'hakkimizda',
          title: 'Hakkımızda İnfo Alanı',
          statu: false,
          ayar: {
            sayfa_k_no: null,
          },
        },
        {
          modul: 'tanitim_video',
          title: 'Tanıtım Video Alanı',
          statu: false,
          ayar: {
            url: null,
          },
        },
        {
          modul: 'urunler',
          title: 'Ürün Tanıtım Alanı',
          statu: false,
          ayar: {
            gosterim_sayisi: 9,
            gorunum: 'grid', // slide "yana kayma şekli"
          },
        },
        {
          modul: 'blog',
          title: 'Blog Haber Alanı',
          statu: false,
          ayar: {
            gosterim_sayisi: 9,
            gorunum: 'grid', // slide "yana kayma şekli"
          },
        },
        {
          modul: 'istatistik',
          title: 'Web Site İstatistik Alanı',
          statu: false,
          // ayar: {
          // },
        },
        {
          modul: 'temsilci_tavsiyesi',
          title: 'Temsilcilerin Tavsiye Ettiği Alanı',
          statu: false,
          ayar: {
            gosterim_sayisi: 9,
            gorunum: 'grid', // slide "yana kayma şekli"
          },
        },
        {
          modul: 'yorumlar',
          title: 'Misafir Yorum Alanı',
          statu: false,
          ayar: {
            gosterim_sayisi: 9,
            gosterim: 'random', // en_son_eklenen, puan_yuksek_olan,
          },
        },
        {
          modul: 'abone_ol',
          title: 'Abone Ol Alanı',
          statu: false,
        },
        {
          modul: 'footer',
          title: 'Footer Alanı',
          statu: false,
          ayar: [
            {
              modul: 'grid_1',
              statu: false,
              type: 'iletisim',
            },
            {
              modul: 'grid_2',
              statu: false,
              type: 'menu',
            },
            {
              modul: 'grid_3',
              statu: false,
              type: 'odeme',
            },
            {
              modul: 'grid_4',
              statu: false,
              type: 'tursab',
            },
          ],
        },
      ],
    });

    expo.colors = ref([
      '#F44336',
      '#E91E63',
      '#AB47BC',
      '#673AB7',
      '#3F51B5',
      '#42A5F5',
      '#03A9F4',
      '#29B6F6',
      '#00BCD4',
      '#009688',
      '#4CAF50',
      '#8BC34A',
      '#CDDC39',
      '#FFEB3B',
      '#FFC107',
      '#FF9800',
      '#F4511E',
      '#795548',
      '#9E9E9E',
      '#607D8B',
      '#000000',
      '#57534E',
      '#78716C',
      '#D6D3D1',
      '#E7E5E4',
      '#FAFAF9',
      '#ffffff',
    ]);

    expo.disabled = (val) => {
      return val == 'header_topbar'
        ? false
        : val == 'header_navbar'
        ? false
        : val == 'slider'
        ? false
        : val == 'footer'
        ? false
        : true;
    };

    const handlarGetAllData = async () => {
      store.dispatch('ayarDashboardGetir').then((res) => {
        if (res.data.success == true) {
          expo.form.value = res.data.data;
        }
      });
    };

    handlarGetAllData();

    expo.handlerOpenModal = (event, i) => {
      console.log(event);
      expo.selectModulIndex.value = i;
      if (event == 'header_topbar') {
        expo.openTopBarHeaderModal.value = true;
      } else if (event == 'header_navbar') {
        expo.openNavBarHeaderModal.value = true;
      } else if (event == 'slider') {
        expo.openSliderModal.value = true;
      } else if (event == 'hakkimizda') {
        expo.openHakkimizdaModal.value = true;
      } else if (event == 'tanitim_video') {
        expo.openTanitimVideoModal.value = true;
      } else if (event == 'urunler') {
        expo.openUrunTanitimiModal.value = true;
      } else if (event == 'blog') {
        expo.openBlogModal.value = true;
      } else if (event == 'istatistikler') {
        expo.openIstatiklerModal.value = true;
      } else if (event == 'temsilci_tavsiyesi') {
        expo.openTavsiyeUrunlerModal.value = true;
      } else if (event == 'yorumlar') {
        expo.openMisafirYorumlariModal.value = true;
      } else if (event == 'abone_ol') {
        expo.openAboneOlModal.value = true;
      } else if (event == 'footer') {
        expo.openFooterModal.value = true;
      }
    };

    expo.handlerOnSubmit = async () => {
      expo.show.value = true;
      await store
        .dispatch('ayarDashboardEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            expo.form.value = res.data.data;
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: expo.form.value.k_no == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            expo.show.value = false;
          }
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            },
            { position: POSITION.BOTTOM_RIGHT }
          );
          expo.show.value = false;
        });
    };

    watch(expo.closeModal, (val) => {
      expo.closeModal.value = false;
      expo.openTopBarHeaderModal.value = false;
      expo.openNavBarHeaderModal.value = false;
      expo.openSliderModal.value = false;
      expo.openHakkimizdaModal.value = false;
      expo.openTanitimVideoModal.value = false;
      expo.openUrunTanitimiModal.value = false;
      expo.openBlogModal.value = false;
      expo.openIstatiklerModal.value = false;
      expo.openTavsiyeUrunlerModal.value = false;
      expo.openMisafirYorumlariModal.value = false;
      expo.openAboneOlModal.value = false;
      expo.openFooterModal.value = false;
    });

    return { ...expo };
  },
};
</script>

<style lang="scss"></style>
