import { render, staticRenderFns } from "./ayarUrunTanitim.vue?vue&type=template&id=5a89c2fa&scoped=true&"
import script from "./ayarUrunTanitim.vue?vue&type=script&lang=js&"
export * from "./ayarUrunTanitim.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a89c2fa",
  null
  
)

export default component.exports