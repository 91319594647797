<template>
  <div>
    <b-modal ref="my-modal" class="rounded-0" centered title="Top Header Güncelle">
      <b-card-text>
        <b-row v-if="updated">
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-1">
              <label for="statu" class="font-weight-bold h6">Telefon Göster</label>
              <b-form-checkbox :checked="form.ayar.telefon" v-model="form.ayar.telefon" switch />
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-1">
              <label for="statu" class="font-weight-bold h6">Sosyal Ağ Göster</label>
              <b-form-checkbox :checked="form.ayar.sosyal" v-model="form.ayar.sosyal" switch />
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-1">
              <label for="statu" class="font-weight-bold h6">Dilleri Göster</label>
              <b-form-checkbox :checked="form.ayar.diller" v-model="form.ayar.diller" switch />
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-1">
              <label for="statu" class="font-weight-bold h6">Kurları Göster</label>
              <b-form-checkbox :checked="form.ayar.kurlar" v-model="form.ayar.kurlar" switch />
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-1">
              <label for="statu" class="font-weight-bold h6">Giriş Kayıt Ol Göster</label>
              <b-form-checkbox :checked="form.ayar.giris" v-model="form.ayar.giris" switch />
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Backgroud Rengi">
              <v-swatches v-model="form.ayar.backgroud" :swatches="colors">
                <b-form-input slot="trigger" :value="form.ayar.backgroud" class="rounded-0" />
              </v-swatches>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Yazı  Rengi">
              <v-swatches v-model="form.ayar.color" :swatches="colors">
                <b-form-input slot="trigger" :value="form.ayar.color" class="rounded-0" />
              </v-swatches>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" @click="$emit('save', true)">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" />
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import vSelect from 'vue-select';
import { ref, watch } from '@vue/composition-api';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
export default {
  components: {
    vSelect,
    VSwatches,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
  },

  setup(props, context) {
    const expo = {};
    expo.updated = ref(false);

    expo.toggleModal = () => {
      context.refs['my-modal'].toggle('#toggle-btn');
      context.emit('closeModal', true);
    };

    watch(
      props,
      (val) => {
        if (val.openModal == true) {
          expo.updated.value = true;
          expo.toggleModal();
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
