<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <div class="d-flex justify-content-start">
        <div class="mr-2 pr-1 border-right" v-if="disabled(modul.modul)">
          <feather-icon icon="MoveIcon" class="cursor-move" :class="disabled(modul.modul) && 'draggable-task-handle'" size="22" />
        </div>
        <div class="mr-2 pr-1 border-right" v-if="modul.ayar">
          <feather-icon icon="SettingsIcon" class="cursor-pointer" size="22" @click="$emit('openModal', modul.modul)" />
        </div>
        <div>
          <strong>{{ modul.title }}</strong>
        </div>
      </div>
      <div>
        <b-form-checkbox :checked="modul.statu" v-model="modul.statu" switch />
      </div>
    </div>
  </b-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
export default {
  props: {
    modul: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};

    expo.disabled = (val) => {
      return val == 'header_topbar'
        ? false
        : val == 'header_navbar'
        ? false
        : val == 'slider'
        ? false
        : val == 'footer'
        ? false
        : true;
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
