<template>
  <div>
    <b-modal ref="my-modal" class="rounded-0" size="lg" centered title="Footer Güncelle">
      <b-card-text>
        <b-row v-if="updated">
          <b-col cols="12">
            <draggable v-model="form.ayar" handler=".draggable-task-handle">
              <transition-group type="transition" name="flip-list">
                <b-card border-variant="primary" v-for="(item, i) in form.ayar" :key="i + 0">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="h4">{{ modulName(item.modul) }}</span>
                    <div><b-form-checkbox :checked="form.ayar[i].statu" v-model="form.ayar[i].statu" switch /></div>
                    <div>
                      <v-select
                        v-model="form.ayar[i].type"
                        :options="sliders"
                        :reduce="(slide) => slide.id"
                        label="title"
                        :clearable="true"
                        style="width: 250px"
                        class="invoice-filter-select d-block"
                      />
                    </div>
                  </div>
                </b-card>
              </transition-group>
            </draggable>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" @click="$emit('save', true)">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" />
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import vSelect from 'vue-select';
import { ref, watch, computed } from '@vue/composition-api';
export default {
  components: {
    draggable,
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const expo = {};
    expo.updated = ref(false);
    expo.sliders = ref([
      {
        id: 'iletisim',
        title: 'İletişim Bilgisi',
      },
      {
        id: 'menu',
        title: 'Menü Alanı',
      },
      {
        id: 'odeme',
        title: 'Ödeme Bilgilendirme',
      },
      {
        id: 'tursab',
        title: 'Türsab Görsel',
      },
    ]);

    expo.modulName = (val) => {
      return val == 'grid_1' ? '1. Kolon' : val == 'grid_2' ? '2. Kolon' : val == 'grid_3' ? '3. Kolon' : '4. Kolon';
    };

    expo.toggleModal = () => {
      context.refs['my-modal'].toggle('#toggle-btn');
      context.emit('closeModal', true);
    };

    watch(
      props,
      (val) => {
        if (val.openModal == true) {
          expo.updated.value = true;
          expo.toggleModal();
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
