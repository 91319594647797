<template>
  <div>
    <b-modal ref="my-modal" class="rounded-0" centered title="Tanıtım Video Güncelle">
      <b-card-text>
        <b-row v-if="updated">
          <b-col cols="12">
            <b-form-group label-cols="5" label="Tanıtım Video Url">
              <b-form-input v-model="form.ayar.url" placeholder="Tanıtım Video Url" class="rounded-0" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" @click="$emit('save', true)">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" />
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { ref, watch } from '@vue/composition-api';
export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const expo = {};
    expo.updated = ref(false);

    expo.toggleModal = () => {
      context.refs['my-modal'].toggle('#toggle-btn');
      context.emit('closeModal', true);
    };

    watch(
      props,
      (val) => {
        if (val.openModal == true) {
          expo.updated.value = true;
          expo.toggleModal();
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
