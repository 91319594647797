<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-group label="Renkler" label-for="h-first-name" label-cols-md="6">
          <v-select
            id="h-first-name"
            v-model="form[0].color"
            :options="colors"
            :reduce="(color) => color.id"
            label="title"
            :clearable="false"
            class="invoice-filter-select d-block"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Yazı Fontu" label-for="h-first-name" label-cols-md="6">
          <v-select
            id="h-first-name"
            v-model="form[0].font_family"
            :options="fonts"
            :reduce="(font) => font.id"
            label="title"
            :clearable="false"
            class="invoice-filter-select d-block"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Button Radius" label-for="h-first-name" label-cols-md="6">
          <b-form-input
            id="h-first-name"
            type="number"
            v-model="form[0].button_radius"
            placeholder="First Name"
            class="rounded-0"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Card Radius" label-for="h-first-name" label-cols-md="6">
          <b-form-input
            id="h-first-name"
            type="number"
            v-model="form[0].card_radius"
            placeholder="First Name"
            class="rounded-0"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import vSelect from 'vue-select';
export default {
  components: {
    vSelect,
  },
  props: {
    form: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const expo = {};
    expo.colors = ref([
      {
        id: 'blue',
        title: 'Mavi Görünüm',
      },
      {
        id: 'kirmizi',
        title: 'Kırmızı Görünüm',
      },
      {
        id: 'turuncu',
        title: 'Turuncu Görünüm',
      },
      {
        id: 'siyah',
        title: 'Siyah Görünüm',
      },
      {
        id: 'yesil',
        title: 'Yesil Görünüm',
      },
      {
        id: 'mor',
        title: 'Mor Görünüm',
      },
    ]);

    expo.fonts = ref([
      {
        id: 'roboto',
        title: 'Roboto',
      },
      {
        id: 'open_sans',
        title: 'Open Sans',
      },
      {
        id: 'lato',
        title: 'Lato',
      },
      {
        id: 'poppins',
        title: 'Poppins',
      },
    ]);
    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
