<template>
  <div>
    <b-modal ref="my-modal" class="rounded-0" centered title="Header NavBar Güncelle">
      <b-card-text>
        <b-row v-if="updated">
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-1">
              <label class="font-weight-bold h6">Logo Göster</label>
              <b-form-checkbox :checked="form.ayar.logo" v-model="form.ayar.logo" switch />
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-1">
              <label class="font-weight-bold h6">Menü Göster</label>
              <b-form-checkbox :checked="form.ayar.menu" v-model="form.ayar.menu" switch />
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-1">
              <label class="font-weight-bold h6">Giriş Göster</label>
              <b-form-checkbox :checked="form.ayar.giris" v-model="form.ayar.giris" switch />
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-1">
              <label class="font-weight-bold h6">Dilleri Göster</label>
              <b-form-checkbox :checked="form.ayar.diller" v-model="form.ayar.diller" switch />
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-1">
              <label class="font-weight-bold h6">Kurları Göster</label>
              <b-form-checkbox :checked="form.ayar.kurlar" v-model="form.ayar.kurlar" switch />
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-1">
              <label class="font-weight-bold h6">Button Göster</label>
              <b-form-checkbox :checked="form.ayar.buton.statu" v-model="form.ayar.buton.statu" switch />
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Button Baslik">
              <b-form-input v-model="form.ayar.buton.baslik" placeholder="Button Baslik" class="rounded-0" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Buton Url">
              <b-form-input v-model="form.ayar.buton.url" placeholder="Buton Url" class="rounded-0" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" @click="$emit('save', true)">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" />
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { ref, watch } from '@vue/composition-api';
export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const expo = {};
    expo.updated = ref(false);
    expo.toggleModal = () => {
      context.refs['my-modal'].toggle('#toggle-btn');
      context.emit('closeModal', true);
    };

    watch(
      props,
      (val) => {
        if (val.openModal == true) {
          expo.updated.value = true;
          expo.toggleModal();
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
